import ImageBanner from '@/components/ui/organisms/image-banner/image-banner'
import { useTrackingsActions } from '@bluheadless/ui-logic/src/hooks/trackings'
import { useLinks } from '@bluheadless/ui-logic/src/hooks/useLinks'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { cx } from '@emotion/css'
import { Fragment } from 'react'

const BannerImage = ({
	aboveTheFold,
	minHeight,
	title,
	titleTag,
	titleColor,
	titleColorMobile,
	subtitle,
	subtitleTag,
	subtitleColor,
	subtitleColorMobile,
	ctas,
	ctaOpenInNewTab,
	image,
	imageConfig,
	sx,
	cssProp,
	className,
	RichContentComponent,
	seoBannerId,
	seoBannerName,
	seoBannerCreative,
	seoBannerPosition,
	Component,
	style,
}) => {
	const { siteName } = useConfig()

	const links = useLinks([
		{ type: ctas[0]?.linkType, href: ctas[0]?.link },
		{ type: ctas[1]?.linkType, href: ctas[1]?.link },
		{ type: ctas[2]?.linkType, href: ctas[2]?.link },
	])

	const { promotionClick } = useTrackingsActions()

	const trackClickEvent = (args) => {
		promotionClick({ ...args })
	}

	return (
		<Component
			sx={{ ...sx, minHeight, width: '100%', height: aboveTheFold ? '100%' : undefined }}
			css={{ ...style, ...cssProp }}
			className={cx(className)}
			titleProps={{ component: titleTag, sx: { color: { xs: titleColorMobile, md: titleColor } } }}
			title={title && RichContentComponent ? <RichContentComponent content={title} component={Fragment} /> : null}
			_title={title}
			subtitleProps={
				subtitle ? { component: subtitleTag, sx: { color: { xs: subtitleColorMobile, md: subtitleColor } } } : undefined
			}
			subtitle={subtitle}
			imageLink={links[0]}
			imageLinkTarget={ctaOpenInNewTab ? '_blank' : '_self'}
			imageSources={{
				xs: image.mobile,
				md: image.desktop,
			}}
			imageProps={imageConfig}
			imageAlt={title ? title + ' | ' + siteName : siteName}
			cta={ctas.map(({ variant, text, color, openInNewTab }, i) => ({
				variant,
				label: text,
				color,
				href: links[i],
				target: openInNewTab ? '_blank' : '_self',
				onClick: (e) => {
					if (seoBannerId && seoBannerName && seoBannerCreative && seoBannerPosition) {
						trackClickEvent({
							event: e,
							id: seoBannerId,
							name: seoBannerName,
							creative: seoBannerCreative,
							position: seoBannerPosition,
						})
					}
				},
			}))}
		/>
	)
}

BannerImage.defaultProps = {
	Component: ImageBanner,
}

export default BannerImage
